<template>
    <DropdownScreen
        class="dropdown-schedule"
        relation="header-schedule"
    >
        <div class="col-span-12 lg:col-span-6">
            <div
                class="text-black text-center text-uppercase font-medium text-fluid-h1 mb-[32px]"
            >
                ГРАФИК РАБОТЫ В ПРАЗДНИКИ
            </div>
            <div class="dropdown-schedule__sub mb-[24px]">
                <p class="mb-[16px]">Уважаемые клиенты!</p>
                <p>В период новогодних праздников наш график работы будет изменен.</p>
            </div>
            <div
                class="dropdown-schedule__text text-pale-sky-100 text-body-small"
            >
                <template v-if="city === 1">
                    <p class="dropdown-schedule__bold">Отдел продаж</p>
                    <p>31 декабря и 1, 2, 7 января - выходные дни.</p>
                    <p>С 3 по 6 января принимаем заказы в обычном режиме 24/7.</p>
                    <p class="dropdown-schedule__bold">Шоурум</p>
                    <p>Москва, улица Ленинская Слобода, 26, бизнес-центр «Омега-2», метро Автозаводская:</p>
                    <br />
                    <p>С 28 по 30 декабря 10:00 - 18:00.</p>
                    <p>31 декабря, 1, 2, 7 января - выходные дни.</p>
                    <p>C 3 по 5 января 10:00 до 18:00.</p>
                    <p>6 января работаем с 10:00  до 17:00.</p>
                    <p>8 января работаем с 10:00 до 18:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Тула -->
                <template v-else-if="city === 6">
                    <p class="dropdown-schedule__bold">Отдел продаж</p>
                    <p>31 декабря и 1, 2, 7 января - выходные дни.</p>
                    <p>С 3 по 6 января принимаем заказы в обычном режиме 24/7.</p>
                    <p class="dropdown-schedule__bold">Шоурум</p>
                    <p>Тула, ул.Лейтейзена, д. 18, ТЦ «Домино», 2 этаж:</p>
                    <br />
                    <p>29 и 30 декабря работаем с 10:00 до 19:00.</p>
                    <p>31 декабря, 1, 2, 7 января - выходные дни.</p>
                    <p>С 3 по 5 и 8 января работаем с 10:00 до 19:00.</p>
                    <p>6 января работаем с 10:00 - 18:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- СПБ -->
                <template v-else-if="city === 7">
                    <p class="dropdown-schedule__bold">Отдел продаж</p>
                    <p>31 декабря и 1, 2, 7 января - выходные дни.</p>
                    <p>С 3 по 6 января принимаем заказы в обычном режиме 24/7.</p>
                    <p class="dropdown-schedule__bold">Шоурум</p>
                    <p>Санкт-Петербург, м. Площадь Александра Невского-2, ул. Кременчугская, д. 9, к. 1:</p>
                    <br />
                    <p>30 декабря работаем с 10:00 до 18:00.</p>
                    <p>31 декабря, 1,2, 7  января - выходные дни.</p>
                    <p>С 3 по 5 января работаем с 10:00 до 18:00.</p>
                    <p>6 января работаем с 10:00 до 19:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- H24 - все остальные регионы -->
                <template v-else-if="isH24 && city !== 1 && city !== 6 && city !== 7">
                    <p class="dropdown-schedule__bold">Отдел продаж</p>
                    <p>31 декабря и 1, 2, 7 января - выходные дни.</p>
                    <p>С 3 по 6 января принимаем заказы в обычном режиме 24/7.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Архангельск -->
                <template v-else-if="city === 42">
                    <p>С 29 декабря по 8 января включительно - выходные дни.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Ижевск -->
                <template v-else-if="city === 80">
                    <p>29 декабря - выходной день.</p>
                    <p>30 и 31 декабря работаем с 10:00 до 17:00.</p>
                    <p>с 1 по 8 января включительно - выходные дни.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Казань -->
                <template v-else-if="city === 8">
                    <p>С 29 декабря по 8 января включительно - выходные дни.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Калининград -->
                <template v-else-if="city === 64">
                    <p>29 и 30 декабря работаем с 11:00 до 17:00.</p>
                    <p>С 31 декабря по 3 января - выходные дни.</p>
                    <p>С 4 по 6 января работаем с 11:00 до 17:00.</p>
                    <p>7 января - выходной день.</p>
                    <p>8 января работаем с 11:00 до 17:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Калуга -->
                <template v-else-if="city === 2">
                    <p>С 29 по 30 декабря работаем с 10:00 до 20:00.</p>
                    <p>31 января работаем с 10:00 до 16:00.</p>
                    <p>С 1 по 2 января - выходные дни.</p>
                    <p>С 3 по 6 января работаем с 10:00 до 20:00.</p>
                    <p>7 января - выходной день.</p>
                    <p>8 января работаем с 10:00 до 20:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Махачкала -->
                <template v-else-if="city === 880">
                    <p>С 29 по 31 декабря работаем с 9:00 до 20:00.</p>
                    <p>С 1 по 3 января - выходные дни.</p>
                    <p>С 4 января работаем с 9:00 до 20:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Набережные Челны -->
                <template v-else-if="city === 827">
                    <p>29 декабря работаем с 10:00 до 18:00.</p>
                    <p>30 декабря работаем с 10:00 до 19:00.</p>
                    <p>С 31 декабря по 2 января - выходные дни.</p>
                    <p>3 января работаем с 10:00 до 19:00.</p>
                    <p>4 и 5 января работаем с 10:00 до 18:00.</p>
                    <p>С 6 по 8 января работаем с 10:00 до 19:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Оренбург -->
                <template v-else-if="city === 21">
                    <p>31 января работаем с 10:00 до 16:00.</p>
                    <p>1 и 2 января - выходные дни.</p>
                    <p>С 3 по 8 января работаем с 10:00 до 20:00.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Ярославль -->
                <template v-else-if="city === 30">
                    <p>С 29 декабря по 8 января включительно - выходные дни.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- Казахстан -->
                <template v-else-if="country === 118">
                    <p>31 декабря работаем с 10:00 до 14:00.</p>
                    <p>С 1 по 3 января - выходные дни.</p>
                    <p>С 4 по 8 января работаем с 10:00 до 18:30.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
                <!-- ЭО - все регионы, кроме франчайзи -->
                <template v-else>
                    <p class="dropdown-schedule__bold">Отдел продаж</p>
                    <p>31 декабря и 1, 2, 7 января - выходные дни.</p>
                    <p>С 3 по 6 января принимаем заказы в обычном режиме 24/7.</p>
                    <p class="dropdown-schedule__bold">Благодарим за понимание и желаем вам счастливого Нового года!</p>
                </template>
            </div>
        </div>
    </DropdownScreen>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import store from '~/store/base'
import DropdownScreen from '~vue/dropdown-wrappers/DropdownScreen.vue'


const city = ref<number | null>(null)
const country = ref<number | null>(null)
const isH24 = computed(() => {
    const { hostname } = new URL(window.location.href)
    return hostname.includes('home24')
})

watch(store.refs.currentCity, () => {
    const { currentCity } = store.refs

    city.value = Number(currentCity.value?.id)
    country.value = Number(currentCity.value?.relationships.country.data.id)
})
</script>

<style lang="scss" scoped>
.dropdown-schedule {
    &__sub {
        @apply text-center text-black font-medium;
        font-size: 24px;
        line-height: 32px;
    }

    &__bold {
        @apply text-black text-body font-medium mt-10;
    }

    :deep {
        .dropdown-screen__outer {
            background-image: url(/assets/images/schedule/schedule-bg.webp);
            background-position: 60vw 100%;
            background-size: auto calc(100vh - 114px);
            background-repeat: no-repeat;

            @media (max-width: 1439px) {
                background-image: none;
            }
        }
    }
}
</style>