import {
    googleDataLayerClickCard,
    googleDataLayerCardCategoryEvent,
    googleDataLayerCardOneClickCategoryEvent
} from "~/helpers/google-datalayer";

document.addEventListener('click', (e) => {
    const target = e.target as HTMLElement

    switch (true) {
        case !!target.closest('.product-card-slider__slider'):
        case !!target.closest('.product-card-header__title'):
        case !!target.closest('.product-card-price'):
        case !!target.closest('a.product-card__button'):
            googleDataLayerClickCard()
            break;

        case !!target.closest('.product-card__button[send-product-cart]'):
            googleDataLayerCardCategoryEvent()
            break;

        case !!target.closest('.product-card__button[send-product-cart-simplified]'):
            googleDataLayerCardOneClickCategoryEvent()
            break;
    }
})
