const blockClassName = 'product-card-slider'

document.addEventListener('DOMContentLoaded', () => {
    initClickEvents()
    initTouchEvents()
})

function initTouchEvents() {
    let touchStartX = 0
    let touchEndX = 0
    let activeContainer: HTMLElement | null = null

    document.addEventListener('touchstart', (e) => {
        const target = (e.target as HTMLElement).closest<HTMLElement>(`.${blockClassName}__slide`)
        if (target) {
            activeContainer = target.closest<HTMLElement>(`.${blockClassName}`)
            touchStartX = e.changedTouches[0].screenX
        }
    });

    document.addEventListener('touchmove', (e) => {
        if (activeContainer) {
            touchEndX = e.changedTouches[0].screenX
        }
    });

    document.addEventListener('touchend', () => {
        if (activeContainer) {
            const slides = [...activeContainer.querySelectorAll<HTMLElement>(`.${blockClassName}__slide`)]
            const currentSlide = activeContainer.querySelector<HTMLElement>(`.${blockClassName}__slide--show`)

            if (touchStartX - touchEndX > 80) {
                changeSliderImage(slides, currentSlide, 'next')
            }

            if (touchEndX - touchStartX > 80) {
                changeSliderImage(slides, currentSlide, 'prev')
            }

            activeContainer = null
        }
    });
}

function initClickEvents() {
    document.addEventListener('click', (e) => {
        const target = e.target as HTMLElement
        const button = target.closest<HTMLElement>(`.${blockClassName}__nav`)

        if (button) {
            const container = button.closest(`.${blockClassName}`)
            const slides = container && [...container.querySelectorAll<HTMLElement>(`.${blockClassName}__slide`)]
            const currentSlide = container?.querySelector<HTMLElement>(`.${blockClassName}__slide--show`)
            const dir = button.classList.contains(`${blockClassName}__nav--prev`) ? 'prev' : 'next'

            if (slides && slides.length > 0) {
                changeSliderImage(slides, currentSlide, dir)
            }

            return
        }
    })
}

function changeSliderImage(slides: HTMLElement[], currentSlide: HTMLElement|null|undefined, dir: string) {
    const selectSlide = (idx: number) => {
        slides.forEach((slide, i) => {
            slide.classList.toggle(`${blockClassName}__slide--show`, i === idx)
        })
        return
    }

    if (!currentSlide) {
        selectSlide(0)
        return
    }

    const index = slides.indexOf(currentSlide)
    const nextIndex = dir === 'prev' ? index - 1 : index + 1
    const loopIndex = dir === 'prev' ? slides.length - 1 : 0
    const isLoop = dir === 'prev' ? (nextIndex < 0) : (nextIndex >= slides.length)

    if (index === -1) {
        selectSlide(0)
        return
    }

    selectSlide(isLoop ? loopIndex : nextIndex)
}
